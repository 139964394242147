import dynamic from 'next/dynamic'

import type { SwiperSlideVideoAsset } from './SwiperSlideVideoAsset'

export const DynamicSwiperSlideVideoAsset = dynamic<
  React.ComponentProps<typeof SwiperSlideVideoAsset>
>(
  () => import('./SwiperSlideVideoAsset').then((mod) => mod.SwiperSlideVideoAsset),
  // problem with hydration
  { ssr: false },
)
