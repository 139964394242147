// swiper css
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/mousewheel'
import 'swiper/css/pagination'

import { useState } from 'react'

import { KeyboardArrowDown, KeyboardArrowUp, Movie } from '@mui/icons-material'
import { Box, Icon, IconButton } from '@mui/material'
import { FreeMode, Mousewheel, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { KnaufImage } from '@/components/KnaufImage'
import { generateImageAlt } from '@/utils/plain/generateImageAlt'

import { DynamicSwiperSlideVideoAsset } from './SwiperSlideVideoAsset'
import type { VideoAsset } from './utils'
import {
  commonNavButtonStyles,
  DESKTOP_GALLERY_CLASS,
  desktopNavButtonStyles,
  HOVER_BOX_SHADOW_SIZE,
  IMAGE_GALLERY_BUTTON_DISABLED_CLASS,
  IMAGE_GALLERY_HEIGHT,
  THUMBS_SIDEBAR_HEIGHT,
  THUMBS_SIDEBAR_WIDTH,
  useNormalizeAssets,
} from './utils'

import Rectangle from './Rectangle.svg'

export const ImageGalleryDesktop = () => {
  const { assets, hasMoreThanOneAsset, productName } = useNormalizeAssets()
  const [activeIndex, setActiveIndex] = useState(0)
  const activeItem = assets[activeIndex]

  const renderThumbnails = () => {
    if (!hasMoreThanOneAsset) return null

    return (
      <Box
        sx={{
          display: 'flex',
          width: THUMBS_SIDEBAR_WIDTH,
          height: IMAGE_GALLERY_HEIGHT,
        }}
      >
        <Swiper
          className={DESKTOP_GALLERY_CLASS}
          direction="vertical"
          spaceBetween={4}
          slidesPerView="auto"
          freeMode
          navigation={{
            prevEl: `.${DESKTOP_GALLERY_CLASS}-prev`,
            nextEl: `.${DESKTOP_GALLERY_CLASS}-next`,
            disabledClass: IMAGE_GALLERY_BUTTON_DISABLED_CLASS,
          }}
          mousewheel={{ releaseOnEdges: true }}
          modules={[FreeMode, Mousewheel, Navigation]}
          data-cy={`${DESKTOP_GALLERY_CLASS}-Swiper`}
        >
          {assets.map((asset, index) => {
            const src = 'isImage' in asset ? asset.url : asset.thumbnail.url
            const damId = asset.id
            const alt = generateImageAlt({
              productName,
              imageTitle: 'isImage' in asset ? asset.name : asset.thumbnail.name,
            })

            const imgSizeWidth = THUMBS_SIDEBAR_WIDTH - 2 * HOVER_BOX_SHADOW_SIZE
            const imgSizeHeight = THUMBS_SIDEBAR_HEIGHT - 2 * HOVER_BOX_SHADOW_SIZE

            return (
              <SwiperSlide
                key={asset.id}
                onMouseEnter={() => {
                  if (index !== activeIndex) setActiveIndex(index)
                }}
                style={{ height: 'auto' }}
              >
                <Box
                  sx={[
                    {
                      position: 'relative',
                      cursor: 'pointer',
                      padding: `${HOVER_BOX_SHADOW_SIZE}px`,
                      display: 'flex',
                    },
                    index === activeIndex && {
                      boxShadow: (theme) =>
                        `inset 0px 0px 0px ${HOVER_BOX_SHADOW_SIZE}px ${theme.palette.primary.main}`,
                    },
                  ]}
                  data-cy={`${DESKTOP_GALLERY_CLASS}-image-${index}`}
                >
                  <KnaufImage
                    src={src}
                    damId={damId}
                    alt={alt}
                    height={imgSizeHeight}
                    width={imgSizeWidth}
                    style={{ objectFit: 'contain' }}
                    priority
                  />
                  {'isVideo' in asset && (
                    <Box
                      sx={{
                        position: 'absolute',
                        right: HOVER_BOX_SHADOW_SIZE,
                        bottom: HOVER_BOX_SHADOW_SIZE,
                        display: 'flex',
                      }}
                    >
                      <Rectangle />
                      <Icon
                        color="primary"
                        fontSize="small"
                        sx={{ position: 'absolute', right: 0, bottom: 0, display: 'flex' }}
                      >
                        <Movie fontSize="small" />
                      </Icon>
                    </Box>
                  )}
                </Box>
              </SwiperSlide>
            )
          })}

          <IconButton
            sx={[commonNavButtonStyles, desktopNavButtonStyles, { top: 8 }]}
            color="primary"
            className={`${DESKTOP_GALLERY_CLASS}-prev`}
            data-cy={`${DESKTOP_GALLERY_CLASS}-prev`}
          >
            <KeyboardArrowUp />
          </IconButton>

          <IconButton
            sx={[commonNavButtonStyles, desktopNavButtonStyles, { bottom: 8 }]}
            color="primary"
            className={`${DESKTOP_GALLERY_CLASS}-next`}
            data-cy={`${DESKTOP_GALLERY_CLASS}-next`}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Swiper>
      </Box>
    )
  }

  const renderImageMainItem = () => {
    if ('isImage' in activeItem) {
      return (
        <KnaufImage
          src={activeItem.url}
          damId={activeItem?.id}
          alt={generateImageAlt({
            productName,
            imageTitle: activeItem?.name,
          })}
          fixedHeight={IMAGE_GALLERY_HEIGHT}
          priority
          style={{ width: '100%' }}
        />
      )
    }

    return null
  }

  // we render all the video, but show only the active, by controling display style
  const renderVideoMainItem = () => {
    const videos = assets.filter((asset) => 'isVideo' in asset)
    if (videos.length === 0) return null

    return videos.map((asset) => (
      <DynamicSwiperSlideVideoAsset
        key={asset.id}
        video={asset as VideoAsset}
        productName={productName}
        isActive={asset.id === activeItem.id}
        hide={asset.id !== activeItem.id}
      />
    ))
  }

  return (
    <Box
      sx={{ display: 'flex', gap: '16px', alignItems: 'center', height: IMAGE_GALLERY_HEIGHT }}
    >
      {renderThumbnails()}

      <Box
        sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        data-cy={`${DESKTOP_GALLERY_CLASS}-MainImage`}
      >
        {renderImageMainItem()}
        {renderVideoMainItem()}
      </Box>
    </Box>
  )
}
